import {DialogService} from "aurelia-dialog";
import {DOM, inject} from "aurelia-framework";
import {Prompt} from "../elements/prompt";
import {I18N} from "aurelia-i18n";
import {translations} from "../classes/translations";
import jqXHR = JQuery.jqXHR;
import {RuntimeInfo} from "../classes/RuntimeInfo";

@inject(DialogService, I18N)
export class DialogMessages {
    dialogService: DialogService;
    i18n: I18N;

    public static instance : DialogMessages;
    constructor(dialogService: DialogService, i18n: I18N) {
        this.dialogService = dialogService;
        this.i18n = i18n;

        DialogMessages.instance = this;
    }

    public static HttpErrorToString(e: any, /*jqXHR,*/ message?: string) {
        if (typeof e.status !== "undefined" && (e.responseText || e.statusText)) {
            let msg = (e.responseText || "").trim();
            let prompt = message || e.statusText || translations.translate("error");
            if (msg.startsWith("<") && msg.endsWith(">")) {
                msg = msg.replace("<body ", "<div data-type='body' ").replace("</body>", "</div>");
                let div = document.createElement("div");
                div.innerHTML = msg;
                let style = div.querySelector("style");
                if (style) {
                    div.removeChild(style);
                }

                let body = div.querySelector("div[data-type='body']");
                if (body) prompt = `${prompt}<hr /><div class="http-error-message">${body.innerHTML}</div>`;
            }
        } else {
            if (e["message"]) return e["message"];
            else return JSON.stringify(e);
        }

        return prompt;
    }

    public showHttpError(e: jqXHR, message?: string): string {
        let prompt = DialogMessages.HttpErrorToString(e, message);
        this.prompt(prompt, this.i18n.tr("error"));
        return prompt;
    }

    prompt(message: string, title = '', isError = false) {
        RuntimeInfo.IsLoading = false;

        return this.dialogService.open({
            viewModel: Prompt,
            model: {
                message: message,
                title: title || this.i18n.tr("information"),
                showNo: false,
                yesText: this.i18n.tr('ok'),
                headerClass: isError ? 'warning' : '',
                headerCorrospondingClass: isError ? 'warning' : ''
            },
            lock: true
        });
    }

    dialog(message, title = '', yesText = '', noText = '', showNo = true) {
        RuntimeInfo.IsLoading = false;
        let result = this.dialogService.open({
            viewModel: Prompt,
            model: {
                message: message,
                title: title || this.i18n.tr("information"),
                yesText: yesText || this.i18n.tr('ok'),
                noText: noText || this.i18n.tr('abort'),
                showNo: showNo
            },
            lock: true
        });

        return result;
    }

    /** Displays a confirm dialogue with the given message
     * @param message The message to present to the user
     * @param [title=''] the title of the dialogue. Defaults to 'Information' when not provided
     * @param [noText=''] the text to be displayed on the Abort-Button
     * @param [yesText=''] the text to be displayed on the OK-Button
     * @returns a boolean value indicating if the user pressed on the Yes/Ok-Button
     */
    confirm(message, title = '', yesText = '', noText = '') : Promise<boolean> {
        return new Promise(resolve => {
            RuntimeInfo.IsLoading = false;
            this.dialogService.open({
                viewModel: Prompt,
                model: {
                    message: message,
                    title: title || this.i18n.tr("information"),
                    yesText: yesText || this.i18n.tr('ok'),
                    noText: noText || this.i18n.tr('abort'),
                    showNo: true
                },
                lock: true
            }).whenClosed(o => resolve(!o.wasCancelled));
        })
    }

    public static Dialog(service: DialogService, message, title : string, yesText : string, noText? : string, showNo = true) {
        RuntimeInfo.IsLoading = false;
        let result = service.open({
            viewModel: Prompt,
            model: {
                message: message,
                title: title,
                yesText: yesText,
                noText: noText,
                showNo: showNo
            },
            position: DialogMessages.vCenterWithBodyObserver,
            lock: true,
            centerHorizontalOnly : true
        });

        return result;
    }

    public static vCenterWithBodyObserver(modalContainer: HTMLElement) {
        const wrapper = <HTMLElement>modalContainer.children[0];
        DialogMessages.recenter(wrapper);
        let observer = new MutationObserver(() => DialogMessages.recenter(wrapper));
        observer.observe(modalContainer.querySelector("ux-dialog-body"), { attributes: true, childList: true });
    }

    private static recenter(element: HTMLElement) {
        const vh = Math.max((<HTMLElement>DOM.querySelectorAll('html')[0]).clientHeight, window.innerHeight || 0);
        element.style.marginTop = Math.max(((vh - element.offsetHeight) / 2), 30) + 'px';
    }

    public static Prompt(service: DialogService, message: string, title?: string, isError: boolean = false) {
        RuntimeInfo.IsLoading = false;
        return service.open({
            viewModel: Prompt,
            position: DialogMessages.vCenterWithBodyObserver,
            model: {
                message: message,
                title: title || translations.translate("information"),
                showNo: false,
                yesText: translations.translate('ok'),
                headerClass: isError ? 'bg-warning' : '',
                headerCorrospondingClass: isError ? 'text-warning' : ''
            },
            lock: true
        });
    }
}
